var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticClass:"main-appbar",attrs:{"flat":"","color":"transparent"}},[_c('app-bar-nav-icon'),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-crown")]),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"chip","transition":"fade-transition"}}):_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v(" Events ("+_vm._s(_vm.totalEvents)+") ")]),_c('v-spacer'),(_vm.hasCreatePermission)?_c('v-btn',{staticClass:"appbar__btn",attrs:{"color":"primary","height":"40px","to":{ name: 'event.list.create' }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.icons.add))]),_vm._v(" Create Event ")],1):_vm._e()],1),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"mx-4",attrs:{"id":"event-list","infinite-scroll-disabled":_vm.loading,"infinite-scroll-distance":"100"}},[_c('div',{staticClass:"appbar mt-7 mb-3"},[_c('v-tabs',[_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = 'exclude'
            _vm.options = 'datetime_start'
            _vm.sortOrder = false
            _vm.activeAction('upcoming')}}},[_vm._v(" Upcoming ")]),_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = 'only'
            _vm.defaultFilter = 'datetime_start'
            _vm.sortOrder = true
            _vm.activeAction('past')}}},[_vm._v(" Past ")]),_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = null
            _vm.defaultFilter = 'datetime_start'
            _vm.sortOrder = true
            _vm.activeAction('all')}}},[_vm._v(" All ")])],1),_c('v-spacer'),_c('v-btn-toggle',{staticClass:"mb-4",attrs:{"mandatory":""},model:{value:(_vm.currentView),callback:function ($$v) {_vm.currentView=$$v},expression:"currentView"}},[_c('v-btn',{attrs:{"value":"list"},on:{"click":function($event){return _vm.toggleView(_vm.VIEW_LIST_TYPE)}}},[_c('v-icon',{class:_vm.currentView === _vm.VIEW_LIST_TYPE ? 'primary-icon' : 'grey-icon'},[_vm._v("mdi-view-headline")])],1),_c('v-btn',{attrs:{"value":"grouped"},on:{"click":function($event){return _vm.toggleView(_vm.VIEW_GROUPED_TYPE)}}},[_c('v-icon',{class:_vm.currentView === _vm.VIEW_GROUPED_TYPE ? 'primary-icon' : 'grey-icon'},[_vm._v("mdi-view-list")])],1)],1)],1),_c('div',{staticClass:"appbar mt-7 mb-3"},[_c('v-spacer'),_c('v-text-field',{ref:"searchField",staticClass:"appbar__filter",attrs:{"flat":"","hide-details":"","solo":"","rounded":"","clearable":"","background-color":"grey lighten-3","label":"Search title, building, category, location and address"},on:{"input":_vm.search},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"ml-10",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Bulk Actions ")])]}}])},[_c('v-list',_vm._l((_vm.actions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1)}),1)],1)],1),(_vm.currentView === _vm.VIEW_LIST_TYPE)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.events,"options":_vm.options,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [25, 50, 100, -1],
      },"server-items-length":_vm.totalEvents,"sort-by":_vm.defaultFilter,"sort-desc":_vm.sortOrder},on:{"click:row":_vm.editItem,"update:options":[function($event){_vm.options=$event},_vm.fetch],"update:sortBy":function($event){_vm.defaultFilter=$event},"update:sort-by":function($event){_vm.defaultFilter=$event},"update:sortDesc":function($event){_vm.sortOrder=$event},"update:sort-desc":function($event){_vm.sortOrder=$event}},scopedSlots:_vm._u([{key:"item.category",fn:function({ item }){return [_vm._v(" "+_vm._s(item.categoryLabel)+" ")]}},{key:"item.building",fn:function({ item }){return [_vm._v(" "+_vm._s(item.buildingName)+" ")]}},{key:"item.datetime_start",fn:function({ item }){return [_vm._v(" "+_vm._s(item.eventDate)+" ")]}}],null,false,3297011304),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(
        !(_vm.events.length > 0) && !_vm.loading && _vm.currentView === _vm.VIEW_LIST_TYPE
      )?_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('p',[_vm._v("No match found.")])]):_vm._e(),(_vm.currentView === _vm.VIEW_GROUPED_TYPE)?_c('v-expansion-panels',_vm._l((_vm.eventsGrouped),function(event){return _c('v-expansion-panel',{key:event.title},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(event.title)+" ("+_vm._s(event.count)+") ")]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.headerGroups,"items":_vm.groupedEvents(event.title).data,"show-select":"","items-per-page":5,"loading":_vm.loading,"server-items-length":_vm.groupedEvents(event.title).meta.total},on:{"click:row":_vm.editItem,"update:options":(options) => {
                _vm.fetchEventsGroupedTitle(options, event.title)
              }},scopedSlots:_vm._u([{key:"item.category",fn:function({ item }){return [_vm._v(" "+_vm._s(item.categoryLabel)+" ")]}},{key:"item.building",fn:function({ item }){return [_vm._v(" "+_vm._s(item.buildingName)+" ")]}},{key:"item.datetime_start",fn:function({ item }){return [_vm._v(" "+_vm._s(item.eventDate)+" ")]}},{key:"item.is_featured",fn:function({ item }){return [_c('v-checkbox',{attrs:{"readonly":"","hide-details":""},model:{value:(item.is_featured),callback:function ($$v) {_vm.$set(item, "is_featured", $$v)},expression:"item.is_featured"}})]}},{key:"item.hide_rsvp",fn:function({ item }){return [_c('v-checkbox',{attrs:{"readonly":"","hide-details":""},model:{value:(item.hide_rsvp),callback:function ($$v) {_vm.$set(item, "hide_rsvp", $$v)},expression:"item.hide_rsvp"}})]}}],null,true),model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}})],1)],1)}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }