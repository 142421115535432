<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-crown</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold">
        Events ({{ totalEvents }})
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'event.list.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Event
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="event-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs>
          <v-tab
            @change="
              defaultStatus = 'exclude'
              options = 'datetime_start'
              sortOrder = false
              activeAction('upcoming')
            "
          >
            Upcoming
          </v-tab>
          <v-tab
            @change="
              defaultStatus = 'only'
              defaultFilter = 'datetime_start'
              sortOrder = true
              activeAction('past')
            "
          >
            Past
          </v-tab>
          <v-tab
            @change="
              defaultStatus = null
              defaultFilter = 'datetime_start'
              sortOrder = true
              activeAction('all')
            "
          >
            All
          </v-tab>
        </v-tabs>

        <v-spacer></v-spacer>

        <!-- Toggle Button to Switch Views -->
        <v-btn-toggle mandatory v-model="currentView" class="mb-4">
          <v-btn @click="toggleView(VIEW_LIST_TYPE)" value="list">
            <v-icon
              :class="
                currentView === VIEW_LIST_TYPE ? 'primary-icon' : 'grey-icon'
              "
              >mdi-view-headline</v-icon
            >
          </v-btn>
          <v-btn @click="toggleView(VIEW_GROUPED_TYPE)" value="grouped">
            <v-icon
              :class="
                currentView === VIEW_GROUPED_TYPE ? 'primary-icon' : 'grey-icon'
              "
              >mdi-view-list</v-icon
            >
          </v-btn>
        </v-btn-toggle>
      </div>

      <div class="appbar mt-7 mb-3">
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search title, building, category, location and address"
          @input="search"
        ></v-text-field>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        v-if="currentView === VIEW_LIST_TYPE"
        :headers="headers"
        :items="events"
        v-model="selected"
        @click:row="editItem"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :server-items-length="totalEvents"
        @update:options="fetch"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
      >
        <template v-slot:item.category="{ item }">
          {{ item.categoryLabel }}
        </template>
        <template v-slot:item.building="{ item }">
          {{ item.buildingName }}
        </template>
        <template v-slot:item.datetime_start="{ item }">
          {{ item.eventDate }}
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="
          !(events.length > 0) && !loading && currentView === VIEW_LIST_TYPE
        "
      >
        <p>No match found.</p>
      </div>
      <v-expansion-panels v-if="currentView === VIEW_GROUPED_TYPE">
        <v-expansion-panel v-for="event in eventsGrouped" :key="event.title">
          <v-expansion-panel-header>
            {{ event.title }} ({{ event.count }})
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-data-table
              :headers="headerGroups"
              :items="groupedEvents(event.title).data"
              v-model="selectedGroup"
              show-select
              @click:row="editItem"
              :items-per-page="5"
              :loading="loading"
              :server-items-length="groupedEvents(event.title).meta.total"
              @update:options="
                (options) => {
                  fetchEventsGroupedTitle(options, event.title)
                }
              "
            >
              <template v-slot:item.category="{ item }">
                {{ item.categoryLabel }}
              </template>
              <template v-slot:item.building="{ item }">
                {{ item.buildingName }}
              </template>
              <template v-slot:item.datetime_start="{ item }">
                {{ item.eventDate }}
              </template>
              <template v-slot:item.is_featured="{ item }">
                <v-checkbox v-model="item.is_featured" readonly hide-details />
              </template>
              <template v-slot:item.hide_rsvp="{ item }">
                <v-checkbox v-model="item.hide_rsvp" readonly hide-details />
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Event Request list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'EventsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    const VIEW_TYPES = {
      LIST: 'list',
      GROUPED: 'grouped',
    }
    return {
      VIEW_LIST_TYPE: VIEW_TYPES.LIST,
      VIEW_GROUPED_TYPE: VIEW_TYPES.GROUPED,
      currentView: VIEW_TYPES.LIST,
      // defaultStatus: 'upcoming',
      currentPage: 1,
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'datetime_start',
      defaultStatus: 'exclude',
      sortOrder: false,
      selected: [],
      headers: [
        {
          text: 'Event Name',
          value: 'title',
          width: '17%',
        },
        {
          text: 'Category',
          value: 'category',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Building',
          value: 'building',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Location',
          value: 'location',
          width: '17%',
        },
        {
          text: 'Event Date/Time',
          value: 'datetime_start',
          width: '17%',
        },
        {
          text: 'Attendees',
          value: 'attendees',
          align: 'center',
          width: '10%',
        },
      ],
      headerGroups: [
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'Building',
          value: 'building',
        },
        {
          text: 'Category',
          value: 'category',
        },
        {
          text: 'Event Date/Time',
          value: 'datetime_start',
        },
        {
          text: 'Location',
          value: 'location',
          align: 'center',
        },
        {
          text: 'Featured',
          value: 'is_featured',
        },
        {
          text: 'Hide RSVP',
          value: 'hide_rsvp',
        },
        {
          text: 'Added Date',
          value: 'addedDate',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      selectedGroup: [],
      options: {},
      eventsGroupedData: {},
    }
  },

  created() {
    this.clearEvents()
    this.fetch()
  },

  computed: {
    ...mapState({
      events: (state) => state.event.list,
      listMeta: (state) => state.event.listMeta,
      permissions: (state) => state.auth.permissions,
      totalEvents: (state) => state.event.totalEvents,
      eventsGrouped: (state) => state.event.eventsGrouped,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions([PERMISSION.EVENTS_CREATE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getEvents: 'event/getEvents',
      getEventsGroupedCategories: 'event/getEventsGroupedCategories',
      getEventsGroupedTitle: 'event/getEventsGroupedTitle',
    }),

    ...mapMutations({
      clearEvents: 'event/clearEventList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
    }),

    filterList(filterValue) {
      this.clearEvents()
      this.fetch()
    },

    // Method to format the date
    formatDateTime(dateTimeStr) {
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]

      // Split the input string into date and time parts
      const [datePart, timePart] = dateTimeStr.split(' ')

      // Split the date part into month, day, and year
      const [monthStr, dayStr, yearStr] = datePart.split('/')

      const month = months[parseInt(monthStr, 10) - 1]
      const day = parseInt(dayStr, 10)
      const year = parseInt(yearStr, 10)

      // Format the time part
      const formattedTime = timePart

      // Get the day of the week
      const eventDate = new Date(year, parseInt(monthStr, 10) - 1, day)
      const dayOfWeek = daysOfWeek[eventDate.getDay()]

      return `${dayOfWeek}, ${day}/${month}/${year} ${formattedTime}`
    },

    activeAction(tab = 'upcoming') {
      this.clearEvents()
      this.fetch(null, 1, tab)
    },

    loadMore() {
      if (this.currentView === this.VIEW_LIST_TYPE) {
        if (
          this.listMeta &&
          this.listMeta.current_page < this.listMeta.last_page
        ) {
          // Increment the current page before making the fetch call
          const nextPage = this.listMeta.current_page + 1
          this.fetch(nextPage)
        }
      }
    },

    search: debounce(function () {
      this.clearEvents()
      this.fetch()
    }, 600),

    async fetch(options, page = 1, tab = '') {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let sort = options?.sortBy[0]
        ? options.sortDesc[0]
          ? '-' + options.sortBy[0]
          : options.sortBy[0]
        : ''
      if (tab) {
        switch (tab) {
          case 'upcoming':
            sort = this.defaultFilter
            break
          default:
            sort = `-${this.defaultFilter}`
        }
      }

      let params = {
        page: Math.max(options?.page, page, 1),
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalEvents
            : options.itemsPerPage
          : 25,
        past_events: this.defaultStatus,
        sort,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      if (this.currentView === this.VIEW_LIST_TYPE) {
        await this.getEvents(params)
          .catch((err) => {
            this.showSnackbar(this.getErrorMessage(err), false)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        params.grouped_by = 'title'
        await this.getEventsGroupedCategories(params)
          .catch((err) => {
            this.showSnackbar(this.getErrorMessage(err), false)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    async fetchEventsGrouped(options) {
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalEvents
            : options.itemsPerPage
          : 25,
        past_events: this.defaultStatus,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getEventsGroupedCategories(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async fetchEventsGroupedTitle(options, title) {
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalEvents
            : options.itemsPerPage
          : 5,
        past_events: this.defaultStatus,
        title,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      const result = await this.getEventsGroupedTitle(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
      this.eventsGroupedData = {
        ...this.eventsGroupedData,
        ...{ [title]: result },
      }
    },

    groupedEvents(title) {
      // const groups = {}
      // for (const event of this.events) {
      //   if (!groups[event.title]) {
      //     groups[event.title] = []
      //   }
      //   groups[event.title].push(event)
      // }
      // return groups
      if (!this.eventsGroupedData[title]) {
        return {
          data: [],
          meta: { total: 0 },
        }
      }

      return this.eventsGroupedData[title]
    },

    editItem(item) {
      this.$router.push({
        name: 'event.list.details',
        params: { id: item.id },
      })
    },

    toggleView(view) {
      if (view) {
        // this.clearEvents()
        this.currentView = view
        this.fetch()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

/* Custom styles for the icons */
.primary-icon {
  color: var(--v-primary-base);
  /* Change to your primary color */
}

.grey-icon {
  color: grey;
  /* Change to your desired grey color */
}

/* Remove the background and border */
.v-btn-toggle.v-btn-toggle--grouped.v-btn-toggle--active .v-btn {
  background: transparent;
  border: none;
}

/* Remove the outline */
.v-btn-toggle.v-btn-toggle--grouped .v-btn:focus {
  box-shadow: none;
}
</style>
